<template>
  <div class="all-medal-wall">
    <img class="static-bg-1" src="https://s3.us-west-1.amazonaws.com/agi.love/img/bg-1.webp" alt="">
    <Header></Header>
    <DataContent></DataContent>
    <Footer></Footer>
    <div v-if="$store.state.visible" class="model_01" @click="$store.commit('SET_VISIBLE', false)"></div>
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import DataContent from './DataContent.vue'

export default {
  name: 'AllMedalWall',
  components: {
    Header,
    Footer,
    DataContent,
  },
}
</script>
<style lang="scss" scoped>
.all-medal-wall {
  color: #fff;
  position: relative;
  
  .static-bg-1 {
    position: absolute;
    top: -99px;
    left: 0;
    z-index: 0;
  }
}
</style>