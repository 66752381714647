<template>
    <div class="grid-container" :class="[screenWidth > 1000 ? 'aaa' : 'grid-container2 w90']">
        <div class="itemOne box1 bg1" :class="[screenWidth > 1000 ? 'box01' : '']">
            <img class="img" src="../../assets/img/data/icon01.png" alt="" />
            <p class="flex-column flex-1">
                <span style="font-size: 18px">{{ config801 }} <em>USDT</em></span>
                <span style="font-size: 14px; color: #999999">{{lang=='en'?'The balance of the disk protection pool':'护盘资金池余额'}}</span>
            </p>
        </div>
        <div class="itemOne box1 bg2">
            <img class="img" src="../../assets/img/data/icon02.png" alt="" />
            <p class="flex-column flex-1">
                <span style="font-size: 18px">{{ config802 }} <em>AJ</em></span>
                <span style="font-size: 14px; color: #999999">{{lang=='en'?'AJ is destroyed cumulatively':'AJ累计销毁'}}</span>
            </p>
        </div>
        <div class="itemOne box1 bg2">
            <img class="img" src="../../assets/img/data/icon03.png" alt="" />
            <p class="flex-column flex-1">
                <span style="font-size: 18px">{{ config803 }} <em>AJ</em></span>
                <span style="font-size: 14px; color: #999999">{{lang=='en'?'AJ Total Circulation':'AJ总发行量'}}</span>
            </p>
        </div>
        <div class="itemOne box1 bg2">
            <img class="img" src="../../assets/img/data/icon04.png" alt="" />
            <p class="flex-column flex-1">
                <span style="font-size: 18px">{{ config804 }} <em>AJ</em></span>
                <span style="font-size: 14px; color: #999999">{{lang=='en'?'AJ Total Circulation':'AJ流通总量'}}</span>
            </p>
        </div>
        <div class="itemOne box1 bg2">
            <img class="img" src="../../assets/img/data/icon05.png" alt="" />
            <p class="flex-column flex-1">
                <span style="font-size: 18px"><em>$ </em>{{ config805 }}</span>
                <span style="font-size: 14px; color: #999999">{{lang=='en'?'AJ current price':'AJ当前价格'}}</span>
            </p>
        </div>
        <div class="itemOne box1 bg1" :class="[screenWidth > 1000 ? 'box01' : '']">
            <img class="img" src="../../assets/img/data/icon06.png" alt="" />
            <p class="flex-column flex-1">
                <span style="font-size: 18px">{{ config806 }} <em>AJ</em></span>
                <span style="font-size: 14px; color: #999999">{{lang=='en'?'AJ destroys the locked position':'AJ暂销毁锁仓额'}}</span>
            </p>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {};
    },
    computed: {
        ...mapState(['screenWidth', 'address', 'config','lang']),
        config801() {
            return this.config[801].val;
        },
        config802() {
            return this.config[802].val;
        },
        config803() {
            return this.config[803].val;
        },
        config804() {
            return this.config[804].val;
        },
        config805() {
            return this.config[805].val;
        },
        config806() {
            return this.config[806].val;
        },
    },

    async created() {},
    mounted() {},
    watch: {},
    methods: {},
};
</script>
<style lang="scss" scoped>
.aaa {
    padding: 50px 75px 80px;
}
.grid-container {
    height: calc(100vh - 120px);
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 80px;
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    position: relative;
}
.w90 {
    width: 90% !important;
    padding-bottom: 0px !important;
    padding-top: 30px !important;
}
.grid-container2 {
    row-gap: 12px !important;
    grid-template-columns: repeat(1, 1fr) !important;
}
.box01 {
    grid-column: 1 / 3;
    // grid-row: 1 / 3;
}
.bg1 {
    background: url('../../assets/img/data/bg1.png') no-repeat, #0e0e0e;
    background-size: 100%, 100%;
}
.bg2 {
    background: url('../../assets/img/data/bg2.png') no-repeat, #0e0e0e;
    background-size: 100%, 100%;
}
.itemOne {
    display: flex;
    align-items: center;
    .img {
        width: 60px;
        height: 60px;
    }
    & > p {
        margin-bottom: 0px;
        padding-left: 20px;
        & > span:nth-of-type(1) {
            em {
                font-style: normal;
                font-size: 12px;
            }
        }
    }
}
</style>
